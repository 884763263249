import React, { useContext, useState, useEffect } from "react";
import {
  Select,
  Form,
  Row,
  Col,
  Input,
  Upload,
  message,
  Button,
  DatePicker,
} from "antd";
import * as Moment from "moment";
import { useTranslation } from "react-i18next";
// @ts-ignore
import UploadFileIcon from "../../../../images/upload-files-icon.svg";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../../context/UserContextProvider";
import { DILIGENCE_FORMS, ENDPOINTS } from "../../../../utils/constants";
import {
  createDilligenceForm,
  updateDilligenceForm,
} from "../../../../utils/api";
import { CountrySearch } from "../../../common/CountrySearch";
import { AuthStateContext } from "../../../../context/AuthContextProvider";
import { deleteUploadedDocument } from "../../../../utils/deleteUploadedDocument";
import { AddressItem } from "../../../common/PlacesAutocomplete";
import RequiredFile from "../../../common/RequiredFile";

function CorporateEntitySlide1({
  diligenceFormId,
  afterSubmit,
  entityTypes,
}: any) {
  const [form] = Form.useForm();
  const [df, setDF] = useState(null);
  const { user, diligenceForm, documents } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const UserDispatch = useContext(UserDispatchContext);
  const [creatingDiligenceForm, setCreatingDiligenceForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [upladDocuments, setUpladDocuments] = useState([]);
  useEffect(() => {
    setUpladDocuments(
      (documents || []).map((d, j) => ({
        uid: d.uid || j,
        status: d.status || "done",
        response: { document: d, url: d.url },
        url: d.url,
      }))
    );
  }, [documents]);

  useEffect(() => {
    form.setFieldsValue({
      // @ts-ignore
      entityType: entityTypes && Object.values(entityTypes)[0].KEY,
    });
  }, [entityTypes]);

  useEffect(() => {
    if (diligenceForm) {
      setDF(diligenceForm);
      const {
        entityType,
        name,
        address,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation,
        addressDetail,
      } = diligenceForm;
      const formValues: any = {
        addressDetail,
        entityType,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation:
          (dateOfIncorporation && Moment(dateOfIncorporation)) ||
          dateOfIncorporation,
        address: { ...address, input: (address && address.asStr) || "" },
      };

      form.setFieldsValue(formValues);
    }
  }, [diligenceForm, diligenceFormId]);

  const syncData = (onSuccess?) => {
    if (creatingDiligenceForm) return;
    const values: any = Object.entries(form.getFieldsValue()).reduce(
      (acc, e) => (e[1] ? { ...acc, [e[0]]: e[1] } : acc),
      {}
    );
    const {
      name,
      registrationId,
      countryOfIncorporation,
      dateOfIncorporation,
      entityType,
      address,
      addressDetail,
    } = values;
    const releventValues = {
      name,
      registrationId,
      countryOfIncorporation,
      dateOfIncorporation,
      entityType,
      address,
      addressDetail,
    };
    let proms;
    if (!df) {
      setCreatingDiligenceForm(true);
      proms = createDilligenceForm(diligenceFormId)(
        accessToken,
        releventValues
      );
    } else {
      proms = updateDilligenceForm(df.id, accessToken, releventValues);
    }
    if (proms) {
      proms
        .then(async r => {
          if (r.ok) {
            const { diligenceForm: dd } = await r.json();
            setDF(dd);
            if (onSuccess) onSuccess(dd);
          }
        })
        .finally(() => {
          setCreatingDiligenceForm(false);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (diligenceForm) {
      const {
        entityType,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation,
        address,
        addressDetail,
      } = diligenceForm;
      const formValues = {
        entityType,
        name,
        registrationId,
        countryOfIncorporation,
        dateOfIncorporation: dateOfIncorporation
          ? Moment(dateOfIncorporation)
          : null,
        address: {
          ...address,
          input: (address && address.asStr) || "",
        },
        addressDetail,
      };
      form.setFieldsValue(formValues);
    } else {
      form.resetFields();
    }
  }, [diligenceFormId, diligenceForm]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 700,
        width: "100%",
        margin: "0 auto",
      }}
    >
      <div className="welcome_block">
        <h2>
          {t("welcome")},{" "}
          <span className="name">
            {(user && user.title) || ""} {(user && user.lname) || ""}
          </span>
        </h2>
        <p>{t("entity_diligence.head1")}</p>
      </div>

      <Form
        form={form}
        layout="vertical"
        name="entity-1"
        size="large"
        className="entity"
        onFinish={() => {
          setIsLoading(true);
          syncData(d => {
            UserDispatch({
              type: "set",
              payload: { diligenceForm: d },
            });
            if (afterSubmit) afterSubmit();
          });
        }}
        onBlur={() => {
          syncData();
        }}
        scrollToFirstError
      >
        <Row align="middle">
          <Col xs={24} md={12}>
            <Form.Item
              name="entityType"
              label={t("entity_type.lbl")}
              rules={[
                {
                  required: true,
                  message: t("entity_type.err.required"),
                },
              ]}
            >
              <Select placeholder={t("entity_type.plh")}>
                {Object.values(entityTypes).map(
                  (e: { KEY: string; VALUE: string }) => (
                    <Select.Option key={e.KEY} value={e.KEY}>
                      {e.VALUE}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col md={12}>
            <div
              className="change-form-type"
              onClick={() => {
                setConfirmModalVisibile("true");
              }}
            >
              Want to register as an individual ?
            </div>
            <Modal
              title="Alert"
              visible={(confirmModalVisible && true) || false}
              onOk={() => {
                setIsDeletingDiligences(true);
                eraseDiligenceFormsByUser({
                  userId: user.id,
                  token: accessToken,
                })
                  .then(r => {
                    if (r.ok) {
                      message.success("Diligence erased with success");
                      // setDiligenceFromTypeId(confirmModalVisible);
                      setConfirmModalVisibile("");
                      updateUser(user.id, accessToken, {
                        selectedDiligenceForm: "individual",
                      })
                        .then(async rr => {
                          if (rr.ok) {
                            UserDispatch({
                              type: "set",
                              payload: {
                                user: (await rr.json()).user,
                                trustDiligenceForm: null,
                                corporateDiligenceForm: null,
                                individualDiligenceForm: null,
                                corporateDocs: null,
                                trustDocs: null,
                                individualDocs: null,
                              },
                            });
                            // UserDispatch({
                            //   type: "set",
                            //   payload: {
                            //     user: (await rr.json()).user,
                            //   },
                            // });
                          }
                        })
                        .catch(e => {
                          console.log(e);
                          setIsDeletingDiligences(false);
                        });
                    }
                  })
                  .catch(e => {
                    console.log(e);
                    setIsDeletingDiligences(false);
                  });
              }}
              onCancel={() => {
                setConfirmModalVisibile("");
              }}
              okText="Yes erase it"
              okType="danger"
              okButtonProps={{ loading: isDeletingDiligences }}
            >
              <p>Are you sure you want to change your diligence form</p>
              <p>
                be aware that hitting ok will erase all the data saved for this
                diligence form
              </p>
            </Modal>
          </Col> */}
        </Row>

        <Row gutter={8}>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              label={t("entity_name.lbl")}
              rules={[
                { required: true, message: t("entity_name.err.required") },
              ]}
            >
              <Input placeholder={t("entity_name.plh")} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="registrationId"
              label={t("registration_id.lbl")}
              rules={[
                {
                  required: true,
                  message: t("registration_id.err.required"),
                },
              ]}
            >
              <Input placeholder={t("registration_id.plh")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col xs={24} md={12}>
            <Form.Item
              name="countryOfIncorporation"
              label={t("country_of_incorporation.lbl")}
              rules={[
                {
                  required: true,
                  message: t("country_of_incorporation.err.required"),
                },
              ]}
            >
              <CountrySearch placeholder={t("country_of_incorporation.plh")} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="dateOfIncorporation"
              label={t("date_of_incorporation.lbl")}
              rules={[
                {
                  required: true,
                  message: t("date_of_incorporation.err.required"),
                },
              ]}
              getValueFromEvent={e => e}
            >
              <DatePicker
                disabledDate={d =>
                  // @ts-ignore
                  d.valueOf() > new Moment().subtract(1, "day").valueOf()
                }
                style={{ width: "100%" }}
                placeholder={t("date_of_incorporation.plh")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={24}>
            <AddressItem
              itemProps={{
                name: "address",
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item name="addressDetail" label={t("address_detail.lbl")}>
              <Input placeholder={t("address_detail.plh")} />
            </Form.Item>
          </Col>
        </Row>

        <div className="section-title text-lower">
          <p className="capitalized" style={{ fontSize: 18 }}>
            {t("upload_documents.label")}
          </p>
        </div>

        <div className="upload-section">
          <RequiredFile text={t("upload_documents.cert_incorp")} />
          <RequiredFile text={t("upload_documents.memo_art_asso")} />
          <RequiredFile text={t("upload_documents.list_shareholders")} />
          <RequiredFile text={t("upload_documents.shareholders_ids")} />
          <Form.Item shouldUpdate={(o, n) => o.entityType !== n.entityType}>
            {() => (
              <Form.Item>
                <Upload.Dragger
                  className="documents-dragger"
                  name="file"
                  listType="picture-card"
                  multiple
                  data={{
                    // docType: e.docType,
                    diligenceFormId: df && df.id,
                  }}
                  action={(): Promise<string> => {
                    // eslint-disable-next-line no-async-promise-executor
                    return new Promise(async (res, rej) => {
                      try {
                        let dil = df;
                        if (!df) {
                          // if (isCreatingDiligenceForm) rej();
                          // setIsCreatingDiligenceForm(true);
                          const re = await createDilligenceForm(
                            DILIGENCE_FORMS.INDIVIDUAL
                          )(accessToken, {});
                          dil = (await re.json()).diligenceForm;

                          setDF(dil);
                          // setIsCreatingDiligenceForm(false);
                        }
                        res(`${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`);
                      } catch (ee) {
                        rej(ee);
                      }
                    });
                  }}
                  headers={{
                    Authorization: `Bearer ${accessToken}`,
                  }}
                  onChange={info => {
                    const { status } = info.file;

                    const fileList = [...info.fileList];
                    setUpladDocuments(
                      fileList.map(file => {
                        if (file.response && file.response.document) {
                          // Component will show file.url as link

                          // eslint-disable-next-line no-param-reassign
                          file.url = file.response.document.url;
                        }
                        return file;
                      })
                    );
                    if (status === "done") {
                      message.success(
                        `${info.file.name} ${t("upload_documents.success")}`
                      );
                    } else if (status === "error") {
                      message.error(
                        `${info.file.name} ${t("upload_documents.fail")}`
                      );
                    }
                  }}
                  onRemove={deleteUploadedDocument(accessToken)}
                  fileList={upladDocuments}
                >
                  <div className="dragArea">
                    <img src={UploadFileIcon} alt="UploadIcon" />
                    <div className="dragArea_uploadTextConatiner">
                      <div className="dragArea_uploadTitle">
                        {t("upload_documents.desc")}
                      </div>
                      <div className="dragArea_uploadDesc">
                        {t("upload_documents.support")}
                      </div>
                    </div>
                    <div className="dragArea_uploadBtn">
                      {t("upload_documents.cta")}
                    </div>
                  </div>
                </Upload.Dragger>
              </Form.Item>
            )}
          </Form.Item>
        </div>
        <div className="section-note">
          <p>{t("indiv_diligence.notif")}</p>
        </div>

        {/* <Row style={{ marginTop: "34px" }} gutter={24}> */}
        <Col
          xs={24}
          md={{ span: 10, offset: 14 }}
          style={{ textAlign: "right" }}
        >
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            loading={isLoading}
            block
            size="large"
            className="main-btn"
          >
            {t("validate")}
          </Button>
        </Col>
        {/* </Row> */}
      </Form>
    </div>
  );
}

export default CorporateEntitySlide1;
