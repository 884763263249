import React, { useState, useContext, useEffect } from "react";
import Proptypes from "prop-types";
import { Col, Form, Row, Button, Upload, message, Input, Radio } from "antd";
import Moment from "moment";
import { useTranslation } from "react-i18next";
// @ts-ignore
import UploadFileIcon from "../../../../images/upload-files-icon.svg";

import {
  createDilligenceForm,
  updateDilligenceForm,
} from "../../../../utils/api";
import {
  DILIGENCE_FORMS,
  // DOCUMENT_TYPES,
  ENDPOINTS,
  COUNTRIES_LIST,
} from "../../../../utils/constants";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../../context/UserContextProvider";
import { Nationalities } from "../../../common/Nationalities";
import { AddressItem } from "../../../common/PlacesAutocomplete";
import { AuthStateContext } from "../../../../context/AuthContextProvider";
import { deleteUploadedDocument } from "../../../../utils/deleteUploadedDocument";
import { CountrySearch } from "../../../common/CountrySearch";
import { DateInput } from "../../../common/DateInput";
import RequiredFile from "../../../common/RequiredFile";

function Individual({ afterSubmit }) {
  const [form] = Form.useForm();
  const [df, setDF] = useState(null);
  // to ensure we don't create an object twice
  const [isCreatingDiligenceForm, setIsCreatingDiligenceForm] = useState(false);
  const { user, diligenceForm, documents } = useContext(UserStateContext);
  const [docs, setDocs] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (diligenceForm) {
      const {
        nationalities,
        placeOfBirth,
        address,
        // originOfAssets,
        isAPep,
        addressDetail,
      } = diligenceForm;
      const formValues: any = {
        isAPep,
        address: { ...address, input: (address && address.asStr) || "" },
        nationalities,
        addressDetail,
      };

      // (nationalities || []).forEach((nat, i) => {
      //   formValues[`nationality-${i}`] = nat;
      // });

      // if (originOfAssets) {
      //   formValues.originOfAssets = originOfAssets.origin;
      //   formValues.sourceOfAssets = originOfAssets.detail;
      // }

      if (placeOfBirth) {
        formValues.birthCountry = placeOfBirth.country;
        formValues.birthCity = placeOfBirth.city;
        formValues.birthDate = placeOfBirth.birthDate;
        formValues.isBirthPlaceNationality =
          (placeOfBirth.isBirthPlaceNationality && "true") || "false";
      }

      form.setFieldsValue(formValues);
    } else {
      form.resetFields();
    }
  }, [diligenceForm]);

  useEffect(() => {
    if (diligenceForm) setDF(diligenceForm);
  }, [diligenceForm]);

  const { accessToken } = useContext(AuthStateContext);
  const UserDispatch = useContext(UserDispatchContext);

  const syncData = (onSuccess?) => {
    if (isCreatingDiligenceForm) return;

    /**
      nationalities,
      birthCountry,
      birthCity,
      birthDate,
      address,
     */
    const values: any = form.getFieldsValue();

    const sanitizedValues: any = {};

    [
      "nationalities",
      "birthCountry",
      "birthCity",
      "birthDate",
      "address",
      "addressDetail",
      "isBirthPlaceNationality",
    ].forEach(key => {
      if (typeof values[key] !== "undefined")
        sanitizedValues[key] = values[key];
    });

    let prms;
    if (!df) {
      setIsCreatingDiligenceForm(true);
      prms = createDilligenceForm(DILIGENCE_FORMS.INDIVIDUAL)(
        accessToken,
        sanitizedValues
      );
    } else {
      prms = updateDilligenceForm(df.id, accessToken, sanitizedValues);
    }
    if (prms) {
      prms
        .then(async r => {
          if (r.ok) {
            const { diligenceForm: dil } = await r.json();
            setDF(dil);
            if (onSuccess) onSuccess(dil);
          }
        })
        .finally(() => {
          setIsCreatingDiligenceForm(false);
        });
    }
  };

  useEffect(() => {
    setDocs(
      (documents || []).map((d, j) => ({
        uid: d.uid || j,
        status: d.status || "done",
        response: { document: d, url: d.url },
        url: d.url,
      }))
    );
  }, [documents]);

  return (
    <div className="signup_slide">
      <div className="welcome_block">
        <h2>
          {t("welcome")},{" "}
          <span className="name">
            {user && user.title} {user && user.lname}{" "}
          </span>
        </h2>
        <p>{t("indiv_diligence.head")}</p>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Form
          size="large"
          onFinish={() => {
            syncData(dil => {
              UserDispatch({
                type: "set",
                payload: { diligenceForm: dil },
              });
              if (afterSubmit) afterSubmit();
            });
          }}
          form={form}
          layout="vertical"
          name="indiv-1"
          onBlur={() => {
            syncData();
          }}
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("birth_place.lbl")}
                required
                rules={[
                  {
                    required: true,
                    message: t("birth_place.err.required"),
                  },
                ]}
                name="birthCountry"
                initialValue={user && user.countryOfResidence}
              >
                <CountrySearch />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                shouldUpdate={(o, n) => o.birthCountry !== n.birthCountry}
              >
                {() => {
                  const birthCountry = COUNTRIES_LIST.find(
                    e => e.code === form.getFieldValue("birthCountry")
                  );
                  return (
                    <Form.Item
                      name="birthCity"
                      label={`${t("birth_city.lbl")} ${
                        birthCountry ? birthCountry.name : ""
                      } ? `}
                      rules={[
                        {
                          required: true,
                          message: t("birth_city.err.required"),
                        },
                      ]}
                    >
                      <Input placeholder={t("birth_city.plh")} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="banner" style={{ marginBottom: "25px" }}>
            <Col xs={24} md={12}>
              <Form.Item
                name="birthDate"
                label={t("birth_date.lbl")}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    validator: (r, v) => {
                      return new Promise((res, rej) => {
                        if (v) {
                          const d = Moment(v, "M/D/YYYY", true);
                          if (
                            d.isValid() &&
                            // @ts-ignore
                            d.isAfter(new Moment().subtract(110, "years"))
                          ) {
                            if (
                              // @ts-ignore
                              d.isBefore(new Moment().subtract(18, "years"))
                            ) {
                              res();
                            } else {
                              rej(t("birth_date.err.required"));
                            }
                          } else rej(t("birth_date.err.required"));
                        } else {
                          rej(t("birth_date.err.required"));
                        }
                      });
                    },
                  },
                ]}
              >
                <DateInput placeholder={t("birth_date.plh")} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="isBirthPlaceNationality"
                label={t("is_birth_place_nat.lbl")}
              >
                <Radio.Group
                  className="nationality-check"
                  onChange={e => {
                    if (
                      e.target.value === "false" &&
                      form.getFieldValue("nationalities").length === 0
                    )
                      form.setFieldsValue({ nationalities: [null] });
                  }}
                >
                  <Radio.Button style={{ width: 137 }} value="true">
                    {t("yes")}
                  </Radio.Button>
                  <Radio.Button style={{ width: 137 }} value="false">
                    {t("no")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item noStyle name="nationalities" initialValue={[]}>
            <Nationalities user={user} form={form} />
          </Form.Item>

          <Row gutter={8}>
            <Col xs={24}>
              <AddressItem />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item name="addressDetail" label={t("address_detail.lbl")}>
                <Input placeholder={t("address_detail.plh")} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <div className="section-info">
              <p>{t("indiv_diligence.rules.main")}</p>
              <ul>
                {(t("indiv_diligence.rules.list") || [])
                  // @ts-expect-error
                  .map((e, i) => (
                    <li key={`${i}-f`}>{e}</li>
                  ))}
              </ul>
            </div>
          </Row>
          <div style={{ color: "#000" }}>
            <h6>{t("upload_documents.label")}</h6>
          </div>

          <div className="upload-section">
            <RequiredFile text={t("upload_documents.id")} />
            <RequiredFile text={t("upload_documents.residence_proof")} />

            <Form.Item>
              <Upload.Dragger
                className="documents-dragger"
                name="file"
                listType="picture-card"
                multiple
                data={{
                  // docType: e.docType,
                  diligenceFormId: df && df.id,
                }}
                action={(): Promise<string> => {
                  // eslint-disable-next-line no-async-promise-executor
                  return new Promise(async (res, rej) => {
                    try {
                      let dil = df;
                      if (!df) {
                        if (isCreatingDiligenceForm) rej();
                        setIsCreatingDiligenceForm(true);
                        const re = await createDilligenceForm(
                          DILIGENCE_FORMS.INDIVIDUAL
                        )(accessToken, {});
                        dil = (await re.json()).diligenceForm;

                        setDF(dil);
                        setIsCreatingDiligenceForm(false);
                      }
                      res(`${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`);
                    } catch (ee) {
                      rej(ee);
                    }
                  });
                }}
                headers={{
                  Authorization: `Bearer ${accessToken}`,
                }}
                onChange={info => {
                  const { status } = info.file;

                  const fileList = [...info.fileList];
                  setDocs(
                    fileList.map(file => {
                      if (file.response && file.response.document) {
                        // Component will show file.url as link

                        // eslint-disable-next-line no-param-reassign
                        file.url = file.response.document.url;
                      }
                      return file;
                    })
                  );

                  if (status === "done") {
                    message.success(
                      `${info.file.name} ${t("upload_documents.success")}`
                    );
                  } else if (status === "error") {
                    message.error(
                      `${info.file.name} ${t("upload_documents.fail")}`
                    );
                  }
                }}
                onRemove={deleteUploadedDocument(accessToken)}
                fileList={docs || []}
              >
                <div className="dragArea">
                  <img src={UploadFileIcon} alt="UploadIcon" />
                  <div className="dragArea_uploadTextConatiner">
                    <div className="dragArea_uploadTitle">
                      {t("upload_documents.desc")}
                    </div>
                    <div className="dragArea_uploadDesc">
                      {t("upload_documents.support")}
                    </div>
                  </div>
                  <div className="dragArea_uploadBtn">
                    {t("upload_documents.cta")}
                  </div>
                </div>
              </Upload.Dragger>
            </Form.Item>
          </div>
          <div className="section-note">{t("indiv_diligence.notif")}</div>

          <Row style={{ marginTop: "34px" }} gutter={24}>
            <Col
              xs={24}
              md={{ span: 12, offset: 12 }}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                block
                onClick={() => {
                  form.submit();
                }}
                className="main-btn"
              >
                {t("continue")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

Individual.propTypes = { afterSubmit: Proptypes.func };

export default Individual;
