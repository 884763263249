import React, { useEffect, useCallback, useContext } from "react";
import gsap from "gsap";
import Proptypes from "prop-types";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import IndividualSlide1 from "./individual/IndividualSlide1";
import IndividualSlide2 from "./individual/IndividualSlide2";
import {
  DILIGENCE_FORMS,
  CORPORATE_ENTITY_TYPES,
  DOCUMENT_TYPES,
  TRUST_FOUNDATION_TYPES,
} from "../../../utils/constants";
import EntitySlide1 from "./entity/EntitySlide1";
import EntitySlide1p5 from "./entity/EntitySlide1.5";
import EntitySlide3 from "./entity/EntitySlide3";
import { updateUser } from "../../../utils/api";
import { UserStateContext } from "../../../context/UserContextProvider";
import { AuthStateContext } from "../../../context/AuthContextProvider";
import { useSyncUser, useReset } from "../../../utils/hooks";
import { RegistrationCompleted } from "../RegistrationCompleted";
import {
  StepsDispatchContext,
  StepsStateContext,
} from "../../../context/StepsContextProvider";
import { getCookie } from "../../../utils/getCookie";

const Slide = ({ children, style = {} }) => (
  <div
    className="slide"
    style={{
      width: "100%",
      // height: "100%",
      position: "absolute",
      top: 0,
      // left: 0,
      opacity: 0,
      left: "100%",
      padding: 10,

      ...style,
    }}
  >
    {children}
  </div>
);
Slide.propTypes = { children: Proptypes.any, style: Proptypes.any };

export function DiligenceFormSignup() {
  const { t } = useTranslation();
  const reset = useReset();
  const cleanCookies = useCallback(() => {
    reset();
    navigate("/");
  }, [reset]);
  useEffect(() => {
    if (getCookie("done")) {
      reset();
      navigate("/");
    }
  }, []);
  const [isLoading] = useSyncUser({
    onFail: cleanCookies,
    failOnNoToken: true,
  });

  const StepsDispatch = useContext(StepsDispatchContext);

  const { activeStep, oldStep, stepsNum } = useContext(StepsStateContext);
  const {
    // activeDiligenceFormTypeId,
    user,
  } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const activeDiligenceFormTypeId = user && user.selectedDiligenceForm;

  useEffect(() => {
    if (activeDiligenceFormTypeId)
      StepsDispatch({
        type: "init",
        payload: {
          stepsNum:
            activeDiligenceFormTypeId === DILIGENCE_FORMS.INDIVIDUAL ? 3 : 4,
        },
      });
  }, [StepsDispatch, activeDiligenceFormTypeId]);

  useEffect(() => {
    if (activeDiligenceFormTypeId && user && user.step)
      StepsDispatch({
        type: "set",
        payload: user.step,
      });
  }, [StepsDispatch, activeDiligenceFormTypeId, user]);

  useEffect(() => {
    if (accessToken && user && user.id) {
      updateUser(user.id, accessToken, { step: activeStep });
    }
  }, [activeStep]);

  useEffect(() => {
    if (isLoading === false) {
      const slides = Array.from(document.getElementsByClassName("slide"));
      if (slides[activeStep]) {
        gsap.to(slides[activeStep], {
          duration: 0.5,
          left: 0,
          opacity: 1,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const slides = Array.from(document.getElementsByClassName("slide"));
    if (slides[activeStep]) {
      gsap.to(slides[activeStep], {
        duration: 0.5,
        left: 0,
        opacity: 1,
        // rotation: 360,
        // ease: "none",
        // paused: true,
      });
      // window.scrollTo(0, 0);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    if (slides[oldStep])
      gsap.to(slides[oldStep], {
        duration: 0.5,
        left: "-100%",
        opacity: 0,
        // rotation: 360,
        // ease: "none",
        // paused: true,
      });
  }, [activeStep, oldStep]);

  const incStep = useCallback(() => StepsDispatch({ type: "inc" }), [
    StepsDispatch,
  ]);

  if (isLoading) return <div key="loading">{t("loading")}...</div>;
  return (
    <div
      key="ctnt"
      className="animform"
      style={{
        display: "block",
        position: "relative",
        // overflow: "hidden",
        height: "",
        width: "100%",
      }}
    >
      {process.env.NODE_ENV === "development" && (
        <div
          style={{
            position: "fixed",
            // background: "blue",
            top: 20,
            left: 20,
            zIndex: 9999999999999,
          }}
        >
          <button
            onClick={() => {
              incStep();
            }}
          >
            +
          </button>
          <button
            onClick={() => {
              StepsDispatch({ type: "dec" });
            }}
          >
            -
          </button>
        </div>
      )}
      <Helmet>
        <style>
          {`
          body{
            transition: background-color 0.6s linear;
          }
          ${
            activeStep === stepsNum - 1
              ? `
            body{
              background-color:#fff;
            }`
              : ""
          }`}
        </style>
      </Helmet>
      {activeDiligenceFormTypeId === DILIGENCE_FORMS.INDIVIDUAL ? (
        <div>
          <Slide>
            <IndividualSlide1 afterSubmit={incStep} />
          </Slide>
          <Slide>
            <IndividualSlide2 afterSubmit={incStep} />
          </Slide>
          <Slide>
            <RegistrationCompleted />
          </Slide>
        </div>
      ) : activeDiligenceFormTypeId === DILIGENCE_FORMS.CORPORATE_ENTITY ? (
        <div>
          <Slide>
            <EntitySlide1
              diligenceFormId={DILIGENCE_FORMS.CORPORATE_ENTITY}
              entityTypes={CORPORATE_ENTITY_TYPES}
              afterSubmit={incStep}
              getDocuments={() => [
                {
                  label: "Certificate of incorporation  & trust deed",
                  docType: DOCUMENT_TYPES.INCORPORATION_CERTIFICATE_TRUST_DEED,
                },
                {
                  label: "Partnership Agreement or Equivalent",
                  docType: DOCUMENT_TYPES.PARTNERSHIP_AGREEMENT_OREQUI,
                },
              ]}
            />
          </Slide>
          <Slide>
            <EntitySlide1p5
              afterSubmit={incStep}
              diligenceFormId={DILIGENCE_FORMS.CORPORATE_ENTITY}
            />
          </Slide>
          <Slide>
            <EntitySlide3 afterSubmit={incStep} />
          </Slide>
          <Slide>
            <RegistrationCompleted />
          </Slide>
        </div>
      ) : activeDiligenceFormTypeId === DILIGENCE_FORMS.TRUST_FOUNDATION ? (
        <div>
          <Slide>
            <EntitySlide1
              diligenceFormId={DILIGENCE_FORMS.TRUST_FOUNDATION}
              entityTypes={TRUST_FOUNDATION_TYPES}
              afterSubmit={incStep}
              getDocuments={e => {
                return e === TRUST_FOUNDATION_TYPES.FOUNDATION.KEY
                  ? [
                      {
                        label: "Foundation Charter",
                        docType: DOCUMENT_TYPES.FOUNDATION_SHARTER,
                      },
                      {
                        label: "Passport copy",
                        docType: DOCUMENT_TYPES.PASSPORT,
                      },
                    ]
                  : [
                      {
                        label: "Certificate of Incorporation",
                        docType: DOCUMENT_TYPES.INCORPORATION_CERTIFICATE,
                      },
                      {
                        label: "Trust Deed",
                        docType: DOCUMENT_TYPES.TRUST_DEED,
                      },
                      {
                        label: "Passport copy",
                        docType: DOCUMENT_TYPES.PASSPORT,
                      },
                    ];
              }}
            />
          </Slide>
          <Slide>
            <EntitySlide1p5
              afterSubmit={incStep}
              diligenceFormId={DILIGENCE_FORMS.TRUST_FOUNDATION}
            />
          </Slide>

          <Slide>
            <EntitySlide3 afterSubmit={incStep} />
          </Slide>

          <Slide>
            <RegistrationCompleted />
          </Slide>
        </div>
      ) : (
        <div>{t("loading")}...</div>
      )}
    </div>
  );
}
