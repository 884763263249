import React, { useState, useContext, useEffect } from "react";
import { Select, Input, Row, Col, Button, Form, message, Upload } from "antd";

import {
  SHAREHOLDERS_STATUS,
  TITLES,
  DILIGENCE_FORMS,
  ENDPOINTS,
} from "../../../../utils/constants";
import {
  updateDilligenceForm,
  createDilligenceForm,
} from "../../../../utils/api";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../../context/UserContextProvider";
import { AuthStateContext } from "../../../../context/AuthContextProvider";
import {
  getArrayOfShareHolders,
  getArrayofPartners,
  getPartnersObject,
  getShareholdersObject,
} from "../../../../utils/formUtils";
// @ts-ignore
import UploadFileIcon from "../../../../images/upload-files-icon.svg";
import { IsPepItem } from "../../IsPepItem";
import { deleteUploadedDocument } from "../../../../utils/deleteUploadedDocument";
import { setCookie } from "../../../../utils/setCookie";

const { Option } = Select;

function EntitySlide3({ afterSubmit }: { afterSubmit: any }) {
  const [form] = Form.useForm();
  const { user, diligenceForm } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);

  const [shareholders, setShareHolders] = useState([1]);
  const [partners, setPartners] = useState([1]);
  const [partnersDocuments, setPartnersDocuments] = useState({});
  const [shareholdersDocuments, setShareholdersDocuments] = useState({});

  const UserDispatch = useContext(UserDispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);

  useEffect(() => {
    if (diligenceForm) {
      const {
        shareHoldersStatus,
        partnersStatus,
        shareHolders: oldShareHolders,
        partners: oldPartners,
      } = diligenceForm;

      setPartnersDocuments(
        diligenceForm.partners.reduce(
          (acc, e, i) => ({
            ...acc,
            [i]: e.documents.flatMap((d, j) =>
              d
                ? [
                    {
                      uid: d.uid || j,
                      status: d.status || "done",
                      response: { document: d, url: d.url },
                      url: d.url,
                    },
                  ]
                : []
            ),
          }),
          {}
        )
      );

      setShareholdersDocuments(
        diligenceForm.shareHolders.reduce(
          (acc, e, i) => ({
            ...acc,
            [i]: e.documents.flatMap((d, j) =>
              d
                ? [
                    {
                      uid: d.uid || j,
                      status: d.status || "done",
                      response: { document: d, url: d.url },
                      url: d.url,
                    },
                  ]
                : []
            ),
          }),
          {}
        )
      );

      setPartners(
        oldPartners.length > 0
          ? new Array(oldPartners.length).fill(1).map((e, i) => i)
          : [1]
      );

      setShareHolders(
        oldShareHolders.length > 0
          ? new Array(oldShareHolders.length).fill(1).map((e, i) => i)
          : [1]
      );

      const formValues: any = {
        shareHoldersStatus,
        partnersStatus,
        ...getPartnersObject(oldPartners),
        ...getShareholdersObject(oldShareHolders),
      };

      form.setFieldsValue(formValues);
    } else {
      form.resetFields();
    }
  }, [diligenceForm]);

  const syncData = (onSuccess?) => {
    if (diligenceForm && diligenceForm.id) {
      const values = form.getFieldsValue();
      updateDilligenceForm(diligenceForm.id, accessToken, {
        partners: getArrayofPartners(
          values.partnersStatus,
          values,
          partnersDocuments,
          partners
        ),
        partnersStatus: values.partnersStatus,
        shareHolders: getArrayOfShareHolders(
          values.shareHoldersStatus,
          values,
          shareholdersDocuments,
          shareholders
        ),
        shareHoldersStatus: values.shareHoldersStatus,
      })
        .then(async r => {
          if (r.ok) {
            const { diligenceForm: df } = await r.json();
            if (onSuccess) onSuccess(df);
          }
        })
        .then(() => setIsLoading(false));
    }
  };

  return (
    <Form
      style={{
        maxWidth: 700,
        margin: "0 auto",
      }}
      name="entity-2"
      form={form}
      size="large"
      layout="vertical"
      onFinish={() => {
        setIsLoading(true);
        syncData(df => {
          UserDispatch({
            type: "set",
            payload: { diligenceForm: df },
          });
          if (afterSubmit) afterSubmit();
          setCookie("done", true);
          // navigate("/");
        });
      }}
      onBlur={() => {
        syncData();
      }}
      scrollToFirstError
    >
      <div className="title">
        <h2>
          Welcome, {user && user.title} {user && user.lname}
        </h2>
        <p>
          Please fill the beneficiaries that you wish to associate with your
          personal account. You can complete this information now or later.{" "}
        </p>
      </div>
      <div className="entity">
        <h3 className="section-title">DIRECTORS OR PARTNERS</h3>

        <Form.Item
          name="partnersStatus"
          label="Do you have directors or partners to add ?"
          initialValue={SHAREHOLDERS_STATUS.NO}
          rules={[
            {
              required: true,
              message: "Please select this",
            },
          ]}
        >
          <Select placeholder="Select a type">
            <Option
              key={SHAREHOLDERS_STATUS.YES}
              value={SHAREHOLDERS_STATUS.YES}
            >
              Yes, I want to add them now
            </Option>
            <Option key={SHAREHOLDERS_STATUS.NO} value={SHAREHOLDERS_STATUS.NO}>
              No, I don’t have any
            </Option>
            <Option
              key={SHAREHOLDERS_STATUS.LATER}
              value={SHAREHOLDERS_STATUS.LATER}
            >
              I want to add them later
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          shouldUpdate={(o, n) => o.partnersStatus !== n.partnersStatus}
        >
          {() =>
            form.getFieldValue("partnersStatus") === SHAREHOLDERS_STATUS.YES ? (
              <div className="editCont">
                <div className="editCont__body">
                  {partners.map(i => {
                    return (
                      <div
                        className="editCont__item"
                        key={`part-${i}`}
                        style={{ paddingBottom: 20 }}
                      >
                        <Row gutter={12}>
                          <Col xs={24} md={3}>
                            <Form.Item
                              name={`partnerTitle-${i}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input   first name",
                                },
                              ]}
                              label="Title"
                              initialValue={TITLES.MR}
                            >
                              <Select placeholder="Title">
                                {Object.values(TITLES).map(elm => (
                                  <Option
                                    key={elm.toLowerCase()}
                                    value={elm.toLowerCase()}
                                  >
                                    {elm}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={9}>
                            <Form.Item
                              name={`partnerFname-${i}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input   first name",
                                },
                              ]}
                              label="First Name"
                            >
                              <Input placeholder="First name" />
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={12}>
                            <Form.Item
                              name={`partnerLname-${i}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input  lirst name",
                                },
                              ]}
                              label="Last Name"
                            >
                              <Input placeholder="Last name" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item>
                          <Upload.Dragger
                            className="documents-dragger"
                            name="file"
                            listType="picture-card"
                            fileList={partnersDocuments[i] || []}
                            multiple
                            data={{
                              for: "partner",
                              diligenceFormId:
                                diligenceForm && diligenceForm.id,
                            }}
                            // action={`${ENDPOINTS.DILIGENCE_FORM}/${
                            //   diligenceForm && diligenceForm.id
                            // }/document`}
                            action={(): Promise<string> => {
                              // eslint-disable-next-line no-async-promise-executor
                              return new Promise(async (res, rej) => {
                                try {
                                  let dil = diligenceForm;
                                  if (!diligenceForm) {
                                    // if (isCreatingDiligenceForm) rej();
                                    // setIsCreatingDiligenceForm(true);
                                    const re = await createDilligenceForm(
                                      DILIGENCE_FORMS.INDIVIDUAL
                                    )(accessToken, {});
                                    dil = (await re.json()).diligenceForm;
                                    UserDispatch({
                                      type: "set",
                                      payload: { diligenceForm: dil },
                                    });
                                    // setDiligenceForm(dil);
                                    // setIsCreatingDiligenceForm(false);
                                  }
                                  res(
                                    `${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`
                                  );
                                } catch (ee) {
                                  rej(ee);
                                }
                              });
                            }}
                            headers={{
                              Authorization: `Bearer ${accessToken}`,
                            }}
                            onChange={info => {
                              const { status } = info.file;

                              const fileList = [...info.fileList];
                              setPartnersDocuments({
                                ...partnersDocuments,
                                [i]: fileList.map(file => {
                                  if (file.response && file.response.document) {
                                    // eslint-disable-next-line no-param-reassign
                                    file.url = file.response.document.url;
                                  }
                                  return file;
                                }),
                              });
                              if (status === "done" || status === "error") {
                                setIsDocumentUploading(false);
                              } else {
                                setIsDocumentUploading(true);
                              }
                              if (status === "done") {
                                message.success(
                                  `${info.file.name} file uploaded successfully.`
                                );
                                syncData();
                              } else if (status === "error") {
                                message.error(
                                  `${info.file.name} file upload failed.`
                                );
                              }
                            }}
                            onRemove={deleteUploadedDocument(accessToken)}
                          >
                            <div className="dragArea">
                              <img src={UploadFileIcon} alt="UploadIcon" />
                              <div className="dragArea_uploadTextConatiner">
                                <div className="dragArea_uploadTitle">
                                  Drag your files here
                                </div>
                                <div className="dragArea_uploadDesc">
                                  Support extension: zip .doc .docx .pdf .jpg...
                                </div>
                              </div>
                              <div className="dragArea_uploadBtn">
                                Upload a document
                              </div>
                            </div>
                          </Upload.Dragger>
                        </Form.Item>
                        <IsPepItem name={`partnersIsPep-${i}`} />
                        <Button
                          onClick={() => {
                            setPartners(o => o.filter(e => e !== i));
                            form.resetFields([
                              `partnerTitle-${i}`,
                              `partnerFname-${i}`,
                              `partnerLname-${i}`,
                              `partnersIsPep-${i}`,
                            ]);
                            setPartnersDocuments({
                              ...partnersDocuments,
                              [i]: [],
                            });
                          }}
                        >
                          remove
                        </Button>
                      </div>
                    );
                  })}
                </div>
                <Button
                  className="editCont__btn"
                  onClick={() => setPartners(o => [...o, o.length + 1])}
                  block
                >
                  + Add another director/partner ?
                </Button>
              </div>
            ) : null
          }
        </Form.Item>

        <h3 className="section-title">
          SHAREHOLDERS & ULTIMATE BENEFICIAL OWNERS
        </h3>

        <Form.Item
          name="shareHoldersStatus"
          label="Do you have shareholders to add ?"
          initialValue={SHAREHOLDERS_STATUS.NO}
          rules={[
            {
              required: true,
              message: "Please select this",
            },
          ]}
        >
          <Select placeholder="Select a type">
            <Option
              key={SHAREHOLDERS_STATUS.YES}
              value={SHAREHOLDERS_STATUS.YES}
            >
              Yes, I want to add them now
            </Option>
            <Option key={SHAREHOLDERS_STATUS.NO} value={SHAREHOLDERS_STATUS.NO}>
              No, I don’t have any
            </Option>
            <Option
              key={SHAREHOLDERS_STATUS.LATER}
              value={SHAREHOLDERS_STATUS.LATER}
            >
              I want to add them later
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          shouldUpdate={(o, n) => o.shareHoldersStatus !== n.shareHoldersStatus}
        >
          {() =>
            form.getFieldValue("shareHoldersStatus") ===
            SHAREHOLDERS_STATUS.YES ? (
              <div className="editCont">
                <div className="editCont__body">
                  {shareholders.map(i => (
                    <div
                      key={`share-${i}`}
                      className="editCont__item"
                      style={{ paddingBottom: 20 }}
                    >
                      <Row gutter={12}>
                        <Col xs={24} md={3}>
                          <Form.Item
                            name={`shareholderTitle-${i}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input   first name",
                              },
                            ]}
                            label="Title"
                            initialValue={TITLES[0]}
                          >
                            <Select placeholder="Title">
                              {Object.values(TITLES).map(elm => (
                                <Option key={elm} value={elm}>
                                  {elm}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={9}>
                          <Form.Item
                            name={`shareholderFname-${i}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input   first name",
                              },
                            ]}
                            label="First Name"
                          >
                            <Input placeholder="First name" />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                          <Form.Item
                            name={`shareholderLname-${i}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input  lirst name",
                              },
                            ]}
                            label="Last Name"
                          >
                            <Input placeholder="Last name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Upload.Dragger
                          className="documents-dragger"
                          name="file"
                          listType="picture-card"
                          fileList={shareholdersDocuments[i] || []}
                          multiple
                          data={{
                            for: "shareholder",
                            diligenceFormId: diligenceForm && diligenceForm.id,
                          }}
                          // action={`${ENDPOINTS.DILIGENCE_FORM}/${
                          //   diligenceForm && diligenceForm.id
                          // }/document`}
                          action={(): Promise<string> => {
                            // eslint-disable-next-line no-async-promise-executor
                            return new Promise(async (res, rej) => {
                              try {
                                let dil = diligenceForm;
                                if (!diligenceForm) {
                                  // if (isCreatingDiligenceForm) rej();
                                  // setIsCreatingDiligenceForm(true);
                                  const re = await createDilligenceForm(
                                    DILIGENCE_FORMS.INDIVIDUAL
                                  )(accessToken, {});
                                  dil = (await re.json()).diligenceForm;
                                  UserDispatch({
                                    type: "set",
                                    payload: { diligenceForm: dil },
                                  });
                                  // setDiligenceForm(dil);
                                  // setIsCreatingDiligenceForm(false);
                                }
                                res(
                                  `${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`
                                );
                              } catch (ee) {
                                rej(ee);
                              }
                            });
                          }}
                          headers={{
                            Authorization: `Bearer ${accessToken}`,
                          }}
                          onChange={info => {
                            const { status } = info.file;

                            const fileList = [...info.fileList];
                            setShareholdersDocuments({
                              ...shareholdersDocuments,
                              [i]: fileList.map(file => {
                                if (file.response && file.response.document) {
                                  // eslint-disable-next-line no-param-reassign
                                  file.url = file.response.document.url;
                                }
                                return file;
                              }),
                            });
                            if (status === "done" || status === "error") {
                              setIsDocumentUploading(false);
                            } else {
                              setIsDocumentUploading(true);
                            }
                            if (status === "done") {
                              message.success(
                                `${info.file.name} file uploaded successfully.`
                              );
                              syncData();
                            } else if (status === "error") {
                              message.error(
                                `${info.file.name} file upload failed.`
                              );
                            }
                          }}
                          onRemove={deleteUploadedDocument(accessToken)}
                        >
                          <div className="dragArea">
                            <img src={UploadFileIcon} alt="UploadIcon" />
                            <div className="dragArea_uploadTextConatiner">
                              <div className="dragArea_uploadTitle">
                                Drag your files here
                              </div>
                              <div className="dragArea_uploadDesc">
                                Support extension: zip .doc .docx .pdf .jpg...
                              </div>
                            </div>
                            <div className="dragArea_uploadBtn">
                              Upload a document
                            </div>
                          </div>
                        </Upload.Dragger>
                      </Form.Item>
                      <IsPepItem name={`shareholderIsPep-${i}`} />{" "}
                      <Button
                        onClick={() => {
                          setShareHolders(o => o.filter(e => e !== i));
                          form.resetFields([
                            `shareholderTitle-${i}`,
                            `shareholderFname-${i}`,
                            `shareholderLname-${i}`,
                            `shareholderIsPep-${i}`,
                          ]);
                          setShareholdersDocuments({
                            ...shareholdersDocuments,
                            [i]: [],
                          });
                        }}
                      >
                        remove
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  className="editCont__btn"
                  onClick={() => setShareHolders(o => [...o, o.length + 1])}
                  block
                >
                  + Add another shareholder ?
                </Button>
              </div>
            ) : null
          }
        </Form.Item>

        <Row style={{ marginTop: "34px" }} gutter={24}>
          <Col
            xs={24}
            md={{ span: 12, offset: 12 }}
            style={{ textAlign: "right" }}
          >
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={isLoading}
              block
              size="large"
              className="main-btn"
              disabled={isDocumentUploading}
            >
              Finish
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default EntitySlide3;
