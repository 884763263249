import React, { useContext, useState, useEffect } from "react";
import {
  Form,
  Upload,
  message,
  Button,
  Switch,
  Popover,
  Col,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
// @ts-ignore
import tooltipIcon from "../../../../images/tooltip.svg";
// @ts-ignore// @ts-ignore
import UploadFileIcon from "../../../../images/upload-files-icon.svg";
import {
  UserStateContext,
  UserDispatchContext,
} from "../../../../context/UserContextProvider";
import {
  DILIGENCE_FORMS,
  ENDPOINTS,
  COMPANY_ROLES,
  COMPANY_ROLES_LABELS,
} from "../../../../utils/constants";
import {
  updateDilligenceForm,
  createDilligenceForm,
} from "../../../../utils/api";
import { AuthStateContext } from "../../../../context/AuthContextProvider";
import { deleteUploadedDocument } from "../../../../utils/deleteUploadedDocument";
import RequiredFile from "../../../common/RequiredFile";

function CorporateEntitySlide1({
  afterSubmit, // diligenceFormId
}: any) {
  const [form] = Form.useForm();
  const { user, diligenceForm, userDocuments } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const UserDispatch = useContext(UserDispatchContext);
  const [creatingDiligenceForm, setCreatingDiligenceForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [upladedDocuments, setUpladedDocuments] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setUpladedDocuments(
      (userDocuments || []).map((d, j) => ({
        uid: d.uid || j,
        status: d.status || "done",
        response: { document: d, url: d.url },
        url: d.url,
      }))
    );
  }, [userDocuments]);

  useEffect(() => {
    if (diligenceForm) {
      const { isAttachedUserAPep, attachedUserRole } = diligenceForm;
      form.setFieldsValue({ isAttachedUserAPep, attachedUserRole });
    }
  }, [diligenceForm]);

  const syncData = (values, onSuccess?) => {
    if (creatingDiligenceForm) return;

    if (diligenceForm && diligenceForm.id)
      updateDilligenceForm(diligenceForm.id, accessToken, values)
        .then(async r => {
          if (r.ok) {
            const { diligenceForm: d } = await r.json();
            if (onSuccess) onSuccess(d);
          }
        })
        .finally(() => {
          setCreatingDiligenceForm(false);
          setIsLoading(false);
        });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 700,
        width: "100%",
        margin: "0 auto",
      }}
    >
      <div className="welcome_block">
        <h2>
          {t("welcome")},{" "}
          <span className="name">
            {(user && user.title) || ""} {(user && user.lname) || ""}
          </span>
        </h2>
        <p>{t("entity_diligence.head2")}</p>
      </div>

      <Form
        form={form}
        layout="vertical"
        name="entity-1.5"
        size="large"
        className="entity"
        style={{ width: "100%" }}
        onFinish={() => {
          setIsLoading(true);
          const {
            isAttachedUserAPep,
            attachedUserRole,
          } = form.getFieldsValue();
          syncData(
            {
              isAttachedUserAPep,
              attachedUserRole,
            },
            d => {
              UserDispatch({
                type: "set",
                payload: { diligenceForm: d },
              });
              if (afterSubmit) afterSubmit();
            }
          );
        }}
        onBlur={e => {
          if (e.target.nodeName !== "BUTTON") {
            const { attachedUserRole } = form.getFieldsValue();
            syncData({ attachedUserRole });
          }
        }}
        onValuesChange={diff => {
          const { isAttachedUserAPep, attachedUserRole } = diff;
          if (typeof attachedUserRole === "undefined")
            syncData({
              isAttachedUserAPep,
            });
        }}
        scrollToFirstError
      >
        <h6>{t("entity_diligence.user_slide.title")}</h6>
        <div className="entityAboutUser">
          <div className="entityAboutUser_title" style={{ marginBottom: 24 }}>
            {t("upload_documents.label")}{" "}
            <span className="capitalized">{(user && user.title) || ""}</span>{" "}
            <span className="capitalized">{(user && user.lname) || ""}</span>
          </div>
          <div className="upload-section">
            <RequiredFile text={t("upload_documents.id")} />
            <Form.Item>
              <Upload.Dragger
                className="documents-dragger"
                name="file"
                listType="picture-card"
                multiple
                data={{
                  for: "user",
                  diligenceFormId: diligenceForm && diligenceForm.id,
                }}
                // action={`${ENDPOINTS.DILIGENCE_FORM}/${
                //   diligenceForm && diligenceForm.id
                // }/document`}
                action={(): Promise<string> => {
                  // eslint-disable-next-line no-async-promise-executor
                  return new Promise(async (res, rej) => {
                    try {
                      let dil = diligenceForm;
                      if (!diligenceForm) {
                        // if (isCreatingDiligenceForm) rej();
                        // setIsCreatingDiligenceForm(true);
                        const re = await createDilligenceForm(
                          DILIGENCE_FORMS.INDIVIDUAL
                        )(accessToken, {});
                        dil = (await re.json()).diligenceForm;
                        UserDispatch({
                          type: "set",
                          payload: { diligenceForm: dil },
                        });
                        // setDiligenceForm(dil);
                        // setIsCreatingDiligenceForm(false);
                      }
                      res(`${ENDPOINTS.DILIGENCE_FORM}/${dil.id}/document`);
                    } catch (ee) {
                      rej(ee);
                    }
                  });
                }}
                headers={{
                  Authorization: `Bearer ${accessToken}`,
                }}
                onChange={info => {
                  const { status } = info.file;

                  const fileList = [...info.fileList];
                  setUpladedDocuments(
                    fileList.map(file => {
                      if (file.response && file.response.document) {
                        // Component will show file.url as link

                        // eslint-disable-next-line no-param-reassign
                        file.url = file.response.document.url;
                      }
                      return file;
                    })
                  );

                  if (status !== "uploading") {
                    // console.log(info.file, info.fileList);
                  }
                  if (status === "done") {
                    message.success(
                      `${info.file.name} ${t("upload_documents.success")}`
                    );
                  } else if (status === "error") {
                    message.error(
                      `${info.file.name} ${t("upload_documents.fail")}`
                    );
                  }
                }}
                onRemove={deleteUploadedDocument(accessToken)}
                fileList={upladedDocuments}
              >
                <div className="dragArea">
                  <img src={UploadFileIcon} alt="UploadIcon" />
                  <div className="dragArea_uploadTextConatiner">
                    <div className="dragArea_uploadTitle">
                      {t("upload_documents.desc")}
                    </div>
                    <div className="dragArea_uploadDesc">
                      {t("upload_documents.support")}
                    </div>
                  </div>
                  <div className="dragArea_uploadBtn">
                    {t("upload_documents.cta")}
                  </div>
                </div>
              </Upload.Dragger>
            </Form.Item>
          </div>
          <Form.Item
            label={`${t("attached_user_role.lbl")} ${
              diligenceForm && diligenceForm.name
            }`}
            name="attachedUserRole"
            rules={[
              {
                required: true,
                message: t("attached_user_role.err.required"),
              },
            ]}
          >
            <Select placeholder={t("attached_user_role.plh")}>
              {Object.values(COMPANY_ROLES).map(e => (
                <Select.Option key={e} value={e}>
                  {COMPANY_ROLES_LABELS[e]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="section-row d-flex justify-content-between align-items-center">
            <div>
              {t("pep.ques")}
              <Popover
                placement="bottom"
                content={
                  <div style={{ color: "#FFF", maxWidth: 500 }}>
                    {t("pep.info")}
                  </div>
                }
                arrowPointAtCenter
              >
                <span>
                  <img src={tooltipIcon} alt="" style={{ marginLeft: 10 }} />{" "}
                </span>
              </Popover>
            </div>
            <Form.Item
              initialValue={false}
              name="isAttachedUserAPep"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        </div>
        <Col
          xs={24}
          md={{ span: 10, offset: 14 }}
          style={{ textAlign: "right", marginTop: 20 }}
        >
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            loading={isLoading}
            block
            size="large"
            className="main-btn"
          >
            {t("validate")}
          </Button>
        </Col>
      </Form>
    </div>
  );
}

export default CorporateEntitySlide1;
