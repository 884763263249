import React from "react";
import Layout from "../../components/Layout";
import { DiligenceSignupHeader } from "../../components/Headers/DiligenceSignupHeader";
import { DiligenceFormSignup } from "../../components/signup/DiligenceFormSignup";
import "../../styles/register.scss";

function Register() {
  return (
    <Layout header={<DiligenceSignupHeader />}>
      <DiligenceFormSignup />
    </Layout>
  );
}

export default Register;
