import React, { useState, useContext, useEffect } from "react";
import { Switch, Row, Col, Button, Form, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { updateDilligenceForm } from "../../../../utils/api";
import { UserStateContext } from "../../../../context/UserContextProvider";
import { OriginOfBusiness } from "../../OriginOfBusiness";
import { AuthStateContext } from "../../../../context/AuthContextProvider";
// @ts-ignore
import tooltipIcon from "../../../../images/tooltip.svg";
import { setCookie } from "../../../../utils/setCookie";
// @ts-ignore
function IndividualSlide2({ afterSubmit }: any) {
  const [form] = Form.useForm();
  const { user, diligenceForm } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const tooltipText = (
    <div style={{ maxWidth: 500, background: "black", color: "#FFF" }}>
      {t("pep.info")}
    </div>
  );
  useEffect(() => {
    if (diligenceForm) {
      const {
        paGt300T,
        assetsGt2m,
        isRepresenter,
        isClean,
        isAPep,
        originOfAssets,
      } = diligenceForm;
      const formValues: any = {
        isAPep,
        paGt300T,
        assetsGt2m,
        isRepresenter,
        isClean,
      };

      if (Array.isArray(originOfAssets)) {
        formValues.originOfAssets = originOfAssets.map(e => {
          formValues[`sourceOfAssets-${e.origin}`] = e.detail;
          return e.origin;
        });
      }
      form.setFieldsValue(formValues);
    }
  }, [diligenceForm]);

  const syncData = (data, onSuccess?) => {
    if (diligenceForm && diligenceForm.id)
      updateDilligenceForm(diligenceForm.id, accessToken, data)
        .then(async r => {
          if (r.ok) {
            const { diligenceForm: dl } = await r.json();

            if (onSuccess) onSuccess(dl);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
  };
  return (
    <div className="signup_slide">
      <div className="welcome_block">
        <h2>
          {t("welcome")},{" "}
          <span className="name">
            {user && user.title} {user && user.lname}
          </span>
        </h2>
        <p>{t("indiv_diligence.head")}</p>
      </div>
      <Form
        name="indiv-2"
        size="large"
        form={form}
        layout={"vertical"}
        onBlur={e => {
          if (e.target.nodeName !== "BUTTON") {
            const { originOfAssets } = form.getFieldsValue();

            syncData({
              originOfAssets: originOfAssets.map(elm => ({
                origin: elm,
                detail: form.getFieldValue(`sourceOfAssets-${elm}`),
              })),
            });
          }
        }}
        onValuesChange={diff => {
          const {
            paGt300T,
            assetsGt2m,
            isRepresenter,
            isClean,
            isAPep,
            originOfAssets,
            sourceOfAssets,
          } = diff;
          if (
            typeof originOfAssets === "undefined" &&
            typeof sourceOfAssets === "undefined"
          )
            syncData({
              paGt300T,
              assetsGt2m,
              isRepresenter,
              isClean,
              isAPep,
            });
        }}
        onFinish={() => {
          setIsLoading(true);

          const {
            originOfAssets,
            paGt300T,
            assetsGt2m,
            isRepresenter,
            isClean,
            isAPep,
          } = form.getFieldsValue();
          syncData(
            {
              originOfAssets: originOfAssets.map(elm => ({
                origin: elm,
                detail: form.getFieldValue(`sourceOfAssets-${elm}`),
              })),
              paGt300T,
              assetsGt2m,
              isRepresenter,
              isClean,
              isAPep,
            },
            () => {
              afterSubmit();

              setCookie("done", true);
            }
          );
        }}
        scrollToFirstError
      >
        <div className="section-title">
          {t("indiv_diligence.accredited_investor.main")}
        </div>
        <div className="section-row d-flex justify-content-between align-items-center">
          <p>{t("indiv_diligence.accredited_investor.desc1")}</p>
          <Form.Item
            initialValue={false}
            name="paGt300T"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="section-row d-flex justify-content-between align-items-center">
          <p>{t("indiv_diligence.accredited_investor.desc2")}</p>
          <Form.Item
            initialValue={false}
            name="assetsGt2m"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
        <OriginOfBusiness form={form} />
        <div className="section-title">{t("pep.main")}</div>
        <div className="section-row d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {t("pep.ques")}
            <div className="main-tooltip">
              <Popover placement="bottom" content={tooltipText}>
                <span>
                  <img src={tooltipIcon} alt="" style={{ marginLeft: 10 }} />{" "}
                </span>
              </Popover>
            </div>
          </div>
          <Form.Item initialValue={false} name="isAPep" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>

        <div className="section-title">
          {t("indiv_diligence.customer_declaration.main")}
        </div>
        <div className="section-row d-flex justify-content-between align-items-start">
          <p>{t("indiv_diligence.customer_declaration.desc1")}</p>
          <Form.Item
            name="isRepresenter"
            initialValue={false}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="section-row d-flex justify-content-between align-items-start">
          <p>{t("indiv_diligence.customer_declaration.desc2")}</p>
          <Form.Item name="isClean" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <Row style={{ marginTop: "34px" }} gutter={24}>
          <Col
            xs={24}
            md={{ span: 12, offset: 12 }}
            style={{ textAlign: "right" }}
          >
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <Form.Item>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        form.submit();
                      }}
                      loading={isLoading}
                      className="main-btn"
                    >
                      {t("continue")}
                    </Button>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default IndividualSlide2;
