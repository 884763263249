import React, { useEffect, useContext, useState } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { Affix, Row } from "antd";
import gsap from "gsap";
import { StepsStateContext } from "../../context/StepsContextProvider";
import { Logo } from "../icons/Logo";
import { Cross } from "../icons/Cross";
import { useReset } from "../../utils/hooks";

export const DiligenceSignupHeader = () => {
  const { activeStep, oldStep, stepsNum } = useContext(StepsStateContext);
  const [display, setDisplay] = useState("flex");
  const reset = useReset();
  useEffect(() => {
    if (oldStep > -1)
      gsap.to(`.dot-${oldStep}`, {
        width: "10px",
        height: "10px",
        duration: 0.5,
        ease: "elastic",
      });
    if (activeStep === 0 && oldStep === -1) {
      gsap.set(".progress-bar", { width: 0 });
      gsap.set(`.dot-${activeStep}`, {
        width: "20px",
        height: "20px",
      });
    } else if (activeStep === stepsNum - 1 && oldStep === 0) {
      setDisplay("none");
      gsap.set("#bars", {
        y: -100,
        duration: 0.5,
      });
    } else {
      gsap.to(".progress-bar", {
        width: `calc(${(activeStep / (stepsNum - 1)) * 100}% - 10px)`,
        duration: 0.5,
        onComplete: () => {
          gsap.to(`.dot-${activeStep}`, {
            width: "20px",
            height: "20px",
            duration: 0.5,
            ease: "elastic",
            onComplete: () => {
              if (activeStep === stepsNum - 1 && oldStep !== 0)
                gsap.to("#bars", {
                  y: -100,
                  duration: 0.5,
                  onComplete: () => {
                    setDisplay("none");
                  },
                });
            },
          });
        },
      });
    }
  }, [activeStep, stepsNum]);

  return (
    <div>
      <Affix offsetTop={0}>
        <header
          style={{
            background: `#000`,
            marginBottom: `40px`,
            height: "90px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="container signup_header">
            <Logo />

            {typeof stepsNum === "number" ? (
              <div
                id="bars"
                style={{
                  display,
                  justifyContent: "space-between",
                  width: "60%",
                  alignItems: "center",
                  height: 10,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 5,
                    width: "calc(100% - 10px)",
                    height: 2,
                    background: "#fff",
                  }}
                />
                <div
                  className="progress-bar"
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 5,
                    // width: "calc(100% - 10px)",
                    height: 2,
                    background: "#BDA06D",
                    zIndex: 2,
                    // width: `${(activeStep / (stepsNum - 1)) * 100}%`,
                  }}
                />
                {new Array(stepsNum).fill(0).map((e, i) => {
                  const s = activeStep === i ? 20 : 10;
                  return (
                    <div
                      key={`dot${i}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "flex-start",
                        width: s,
                        height: s,
                        zIndex: 100,
                        position: "relative",
                      }}
                    >
                      <div
                        className={`dot-${i}`}
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          background: i <= activeStep ? "#BDA06D" : "#fff",
                          transformOrigin: "center",
                          margin: "auto",
                          // marginTop: "auto",
                          // marginBottom: "auto",
                          // zIndex: 100,
                        }}
                      />
                      <div
                        style={{ position: "absolute", top: s, color: "#fff" }}
                      >
                        Step{i + 1}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <Row
              justify="space-between"
              align="middle"
              gutter={16}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                reset();
                navigate("/");
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  lineHeight: "26px",
                  color: "#fff",
                  fontSize: 14,
                  marginRight: 12,
                }}
              >
                Close
              </div>
              <Cross />
            </Row>
          </div>
        </header>
      </Affix>{" "}
    </div>
  );
};

DiligenceSignupHeader.propTypes = {
  siteTitle: PropTypes.string,
};

DiligenceSignupHeader.defaultProps = {
  siteTitle: ``,
};
