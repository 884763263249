import React from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
// @ts-ignore
import successIcon from "../../images/success-check.svg";

export const RegistrationCompleted = ({ msg }: any) => {
  const { t } = useTranslation();

  return (
    <div
      className="registration-completed"
      style={{ maxWidth: 700, margin: "0 auto" }}
    >
      <Result
        icon={<img src={successIcon} alt="send" />}
        status="success"
        title={t("reg_completed.title")}
        subTitle={msg || t("reg_completed.msg")}
      />
    </div>
  );
};
